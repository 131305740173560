@media only screen and (max-width: 1920px)  { 


    #linha_h{
        border-top: 2px solid #bbb !important;
        width:70%;
    }

    .imge{
        margin-top: 100px;
        width: 230px;
        height: 120px;

    }

    .padding_logrado{
        padding-left: 70px !important;
        padding-right:143px !important;
    }

    .marg_circ{
     margin-left: 205px !important;
 }

 .circulo{
    width:50px;
    height:50px;
    border-radius:50%;
    z-index:5;
    background:#2bbbad;

}

.faixa_contato{

    background-color: #f1f1f1;
    width: 50%;
    height: 630px;

}

.ft{

    margin-left: 40px;
    margin-top: 100px !important;

}
.md{
    font-size: 1.9em !important;
    position: absolute;
    color: #fff;
    padding-left: 10px !important;
    padding-top: 5px !important;
}

.lft{

    margin-top: 50px;
    margin-left: 50px;
}
.txt{

    padding-left: 200px;
    padding-bottom: 200px;
}

.padding_bd_ret{
    font-size: 18px; 
    padding-left: 5px;
    margin-top: -15.5px;
}

.mail{
    font-weight: 500;
    color: #535353 ;
}

.titulo{
    font-weight: 600;
    color: #535353 ;
    
}

#retanglo {

    width: 580px;
    height: 350px;
    border: 1.5px solid #dad9d9 !important;
    border-radius: 15px;
    margin-top: 150px; 
    margin-left: 70px
    
}

#retangulo_bord {
    width: 340px;
    height: 30px;
    background: #f1f1f1;
    display: inline-block;
    font-weight: 600; 
    color: #535353;
    padding-left: 35px;
}

#texto{

    padding-top: 20px;
    padding-left: 40px;
}

#contato_email
{
    margin-top: 120px; 
    margin-left: 70px;
}

}

@media only screen and (max-width: 481px) and (min-width: 414px) { 

  #linha_h{
    border-top: 2px solid #bbb !important;
    width:70%;

}

.imge{
    margin-top: 100px;
    width: 210px !important;
    height: 110px !important;

}

.h4_txt{
 margin-top:-10px !important;
}

.padding_logrado{
    padding-left: 30px !important;
    padding-right:143px !important;

}

.marg_circ{
 margin-left:80px !important;
}

.circulo_row{
  margin-top: -30px !important;
  margin-bottom: 30px !important;
}

.circulo{
    width:35px;
    height:35px;
    border-radius:50%;
    z-index:5;
    background:#2bbbad;
    margin-top: 25px !important;
    margin-bottom: 30px !important;            
}

.faixa_contato{

    background-color: #f1f1f1;
    width: 100% !important;
    height: 300px !important;

}

.ft{

    margin-left: 40px;
    margin-top: 100px !important;

}
.md{
    font-size: 1.3em !important;
    position: absolute;
    color: #fff;
    padding-left: 7.5px !important;
    padding-top: 4.5px !important;
}

.lft{

    margin-top: 50px;
    margin-left: 40px;
}
.txt{

    padding-left: 200px;
    padding-bottom: 200px;
}

.padding_bd_ret{
    font-size: 18px; 
    padding-left: 5px;
    margin-top: -15.5px;
}

.mail{
    font-weight: 500;
    color: #535353 ;
    font-size: 13px;
}

.titulo{
    font-weight: 600;
    color: #535353 ;
    font-size: 13px;

}

#retanglo {

    width: 370px !important;
    height: 330px !important;
    border: 1.5px solid #dad9d9 !important;
    border-radius: 15px;
    margin-top: 100px !important; 
    margin-left: 10px !important

}

#retangulo_bord {
    width: 300px !important;
    height: 30px;
    background: #f1f1f1;
    display: inline-block;
    font-weight: 600; 
    color: #535353;
    padding-left: 15px !important;
    font-size: 16px;
}

#texto{

    padding-top: 10px !important;
    padding-left: 20px !important;
}

#contato_email
{
    margin-top: 120px; 
    margin-left: 70px;
}



}



@media only screen and (max-width: 376px) and (min-width: 360px) { 

    #linha_h{
        border-top: 2px solid #bbb !important;
        width:70%;

    }

    .imge{
        margin-top: 100px;
        width: 210px !important;
        height: 110px !important;

    }

    .h4_txt{
     margin-top:-10px !important;
 }

 .padding_logrado{
    padding-left: 0px !important;
    padding-right:143px !important;

}

.marg_circ{
 margin-left:50px !important;
}

.circulo_row{
  margin-top: -30px !important;
  margin-bottom: 30px !important;
}

.circulo{
    width:35px;
    height:35px;
    border-radius:50%;
    z-index:5;
    background:#2bbbad;
    margin-top: 25px !important;
    margin-bottom: 30px !important;            
}

.faixa_contato{

    background-color: #f1f1f1;
    width: 100% !important;
    height: 300px !important;

}

.ft{

    margin-left: 40px;
    margin-top: 100px !important;

}
.md{
    font-size: 1.3em !important;
    position: absolute;
    color: #fff;
    padding-left: 7.5px !important;
    padding-top: 4px !important;
}

.lft{

    margin-top: 50px;
    margin-left: 40px;
}
.txt{

    padding-left: 200px;
    padding-bottom: 200px;
}

.padding_bd_ret{
    font-size: 18px; 
    padding-left: 5px;
    margin-top: -15.5px;
}

.mail{
    font-weight: 500;
    color: #535353 ;
    font-size: 13px;
}

.titulo{
    font-weight: 600;
    color: #535353 ;
    font-size: 13px;

}

#retanglo {

    width: 340px !important;
    height: 330px !important;
    border: 1.5px solid #dad9d9 !important;
    border-radius: 15px;
    margin-top: 100px !important; 
    margin-left: -3px !important

}

#retangulo_bord {
    width: 300px !important;
    height: 30px;
    background: #f1f1f1;
    display: inline-block;
    font-weight: 600; 
    color: #535353;
    padding-left: 15px !important;
    font-size: 16px;
}

#texto{

    padding-top: 10px !important;
    padding-left: 20px !important;
}

#contato_email
{
    margin-top: 120px; 
    margin-left: 70px;
}



}


@media only screen and (max-width: 767px) and (min-width: 482px) { 

    #linha_h{
        border-top: 2px solid #bbb !important;
        width:70%;

    }

    .imge{
        margin-top: 100px;
        width: 230px !important;
        height: 120px !important;

    }

    .h4_txt{
     margin-top:-10px !important;
 }

 .padding_logrado{
    padding-left: 70px !important;
    padding-right:143px !important;

}

.marg_circ{
 margin-left:240px !important;
}

.circulo_row{
  margin-top: -30px !important;
  margin-bottom: 30px !important;
}

.circulo{
    width:40px;
    height:40px;
    border-radius:50%;
    z-index:5;
    background:#2bbbad;
    margin-top: 25px !important;
    margin-bottom: 30px !important;            
}

.faixa_contato{

    background-color: #f1f1f1;
    width: 100% !important;
    height: 300px !important;

}

.ft{

    margin-left: 40px;
    margin-top: 100px !important;

}
.md{
    font-size: 1.7em !important;
    position: absolute;
    color: #fff;
    padding-left: 7px !important;
    padding-top: 3px !important;
}

.lft{

    margin-top: 50px;
    margin-left: 40px;
}
.txt{

    padding-left: 200px;
    padding-bottom: 200px;
}

.padding_bd_ret{
    font-size: 18px; 
    padding-left: 5px;
    margin-top: -15.5px;
}

.mail{
    font-weight: 500;
    color: #535353 ;
    
}

.titulo{
    font-weight: 600;
    color: #535353 ;
    

}

#retanglo {

    width: 580px;
    height: 350px;
    border: 1.5px solid #dad9d9 !important;
    border-radius: 15px;
    margin-top: 150px; 
    margin-left: 70px
    
}

#retangulo_bord {
    width: 340px;
    height: 30px;
    background: #f1f1f1;
    display: inline-block;
    font-weight: 600; 
    color: #535353;
    padding-left: 35px;
}

#texto{

    padding-top: 20px;
    padding-left: 40px;
}

#contato_email
{
    margin-top: 120px; 
    margin-left: 70px;
}



}


@media only screen and (max-width: 1024px) and (min-width: 768px) { 

     #linha_h{
        border-top: 2px solid #bbb !important;
        width:70%;
    }

    .imge{
        margin-top: 100px;
        width: 230px;
        height: 120px;

    }

    .padding_logrado{
        padding-left: 0px !important;
        padding-right:143px !important;
    }

    .marg_circ{
     margin-left: 90px !important;
 }

 .circulo{
    width:35px;
    height:35px;
    border-radius:50%;
    z-index:5;
    background:#2bbbad;
           
}

.content_contato{
    min-height: 720px !important;
}

.faixa_contato{


    background-color: #f1f1f1;
    width: 50%;
    height: 420px;
    margin-top: 10%;

}

.faixa {
    align-items: flex-start;
}


.ft{

    margin-left: 40px;
    margin-top: 100px !important;

}
.md{
    font-size: 1.5em !important;
    position: absolute;
    color: #fff;
    padding-left: 6px !important;
    padding-top: 3px !important;
}

.lft{

    margin-top: 20px;
    margin-left: 30px;
}
.txt{

    padding-left: 200px;
    padding-bottom: 200px;
}

.padding_bd_ret{
    font-size: 18px; 
    padding-left: 2px;
    margin-top: -15.5px;
}

.mail{
    font-weight: 500;
    color: #535353 ;
    font-size: 13px;
}

.titulo{
    font-weight: 600;
    color: #535353 ;
    font-size: 13px;
    
}

#retanglo {

    width: 360px;
    height: 350px;
    border: 1.5px solid #dad9d9 !important;
    border-radius: 15px;
    margin-top: 150px; 
    margin-left: 10px
    
}

#retangulo_bord {
    width: 300px;
    height: 30px;
    background: #f1f1f1;
    display: inline-block;
    font-weight: 600; 
    color: #535353;
    padding-left: 20px;
}

#texto{

    padding-top: 20px;
    padding-left: 15px;
}

#contato_email
{
    margin-top: 120px; 
    margin-left: 70px;
}



}


