/**
Cores
	verde: #2bbbad
	preto: 231F20
**/
*{
	font-family: 'Work Sans', sans-serif;
	font-weight:300;
	color:#231F20;
}
h1, h2, h3, h4, h5, h6{
    font-weight: 700;
    text-transform: uppercase;
}
p, a, label, input, select option, button, textarea, li{
	font-weight: 300;
	font-size: 15px;
	text-align:justify;
}
b, strong{
	font-weight: 700;
}
a, a:visited, a:hover{
	text-decoration: none !important;
	color:#231F20;
}
a:hover{
	color:#000;
}
a:focus {
    outline: none;
    outline-offset:0;
}
::selection {
  background: #231F20; /* WebKit/Blink Browsers */
  color:#fff;
}
::-moz-selection {
  background: #231F20; /* Gecko Browsers */
  color:#fff;
}
/* Faixas */
.faixa{
	display: flex;
	align-items: center;
	justify-content: center;
	overflow:auto;
	padding:0;
}
.faixa.padding{
	padding:50px 0;
}
.faixa-cinza{
	background:#F1F1F1;
}
.faixa>.container-fluid{
	width:100%;
}
.ca-faixa-titulo{
	font-size: 42px;
	text-transform:uppercase;
	margin-top:0;
}
.ca-conteudo-titulo{
	font-size: 22px;
	font-weight:500;
}
.ca-conteudo-subtitulo{
	font-size: 20px;
	text-transform:inherit;
}
#ca-quem-somos-imagem, #ca-como-fazemos-imagem{
	overflow: hidden;
    position: relative;
    width: 100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
}
.espacador-horizontal{
	display:table;
	height:30px;
	width:100%;
}
/* Imagem cinza */
.img-cinza{
	-webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
    opacity: 0.5;
    -webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.img-cinza:hover{
	-webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
    filter: grayscale(0%);
    opacity:1;
}
.media-heading{
	text-align:left;
}

/* Lista de icones */
.ca-media-icones .fa-circle:before, .ca-lista-icones .fa-circle:before{
	color:#2bbbad;
}
.ca-media-icones p{
	margin-bottom:0;
}
.ca-leia-mais-botao{
	text-align:right;
	font-size:1.7em;
}
.ca-lista-icones li{
	text-align:center;
	margin-bottom: 25px;
}
.ca-lista-icones h4{
	margin:2px 0;
}
/* Icones */
.ca-icone-mais-branco{
	color:#ffffff;
}
/* Ícone de + das imagens */
.ca-imagem-icone-wrap{
	position:relative;
	cursor:pointer;
}
.up-hover{
	position:absolute;
	bottom:0;
	left:50%;
    -ms-transform: translate(-50%,0); 
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
	opacity:0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.ca-imagem-icone-wrap:hover .up-hover{
	bottom:40%;
	opacity:1;
}
.ca-imagem-icone-wrap:hover:before{
	content:' ';
	width:100%;
	height:100%;
	position:absolute;
	background:rgba(0,0,0,0.5);
}
.ca-construindo-item .ca-imagem-icone-wrap:hover .up-hover{
	bottom:27%;
}
.ca-construindo-item-resumo{
	margin:0;
}
/* Lista de elementos justificados */
.ca-lista-justificada{
	-ms-box-orient: horizontal;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around; 
	justify-content: space-around; 
}
/* NAVBAR */
#ca-nav{
	background: rgba(35,31,32,0.50);
}
#ca-nav.active{
	background: rgba(35,31,32,1);
}
#ca-logo{
	display:table;
}
#ca-menu-itens{
	border:none;
}
#ca-menu-itens li a{
	font-weight:300;
	font-size: 16px;
	color: #FFFFFF;
	padding: 5px 0px;
	margin:25px 10px;
}
#ca-menu-itens li a:hover,#ca-menu-itens li.active a:hover, #ca-menu-itens li a:focus{
	background:none;
}
#ca-menu-itens li a::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    background: #2bbbad;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
}
#ca-menu-itens li a:hover::after, #ca-menu-itens li.active a::after{
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}

/* Slider */
.ms-caption{
	background: rgba(0,0,0,0.50);
	padding:20px;
	text-align:right;
	max-width:470px;
}
.ca-slider-titulo{
	font-size: 36px;
	color: #FFFFFF;
	font-weight:200;
	text-align:right;
}
.ca-slider-frase{
	font-size: 18px;
	line-height: 18px;
	color: #FFFFFF;
	text-align:right;
}
.master-slider{
	-moz-transform:translate3d(0,0,1px);
}
.ms-nav-prev,.ms-nav-next,.carousel-control{
    width: 30px;
    height: 40px;
    top:50%;
    left: -20px;
    margin-top: -20px;
    position: absolute;
    cursor: pointer;
	display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    font-size:3em;
    text-align:center;
}
.ms-nav-next:before, .carousel-control.right:before, .mfp-arrow-right:before {
	content: "\f3d3";
}
.ms-nav-prev:before, .carousel-control.left:before, .mfp-arrow-left:before{
	content: "\f3d2";
}
.ms-nav-next, .ms-nav-prev, .carousel-control{
	background:none !important;
	color:#fff;
	border:2px solid #fff;
	border-radius:100%;
	padding:10px 15px;
}
.ms-nav-next{
	right:10px;
}
.ms-nav-prev{
	left:10px;
}
.carousel-control{
	color:#231F20;
	border-color:#231F20;
}
.carousel-control.right{
	right:-30px;
}
.carousel-control.left{
	left:-30px;
}
#ca-novidades-slider .item{
	min-height:410px;
}

button.mfp-arrow-right, button.mfp-arrow-left {
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    font-size:3em;
    text-align:center;
    color:#fff;
    border:2px solid #fff;
    border-radius:100%;
    margin:0;
    padding:0;
    width:64px;
    height:64px;
}
button.mfp-arrow-right{
	right:10px;
}
button.mfp-arrow-left{
	left:10px;
}
.mfp-arrow-right:before,.mfp-arrow-left:before {
	border:none;
	text-align:center;
	margin:0;
	position:relative;
	width:64px;
	height:64px;
	padding-top:10px;
}
.mfp-arrow-right:after, .mfp-arrow-left:after {
	opacity:0;
	border:none;
}
.mfp-arrow:active {
    margin-top: 0;
}

#ca-slider-portfolio .ms-nav-next, #ca-slider-portfolio .ms-nav-prev{
	width:15px;
	height:20px;
	font-size:2em;
	padding:10px 10px 5px 10px;
    line-height: 0.6;
}
.ms-bullet, .carousel-indicators li,.lSSlideOuter .lSPager.lSpg li a{
	border:none;
	border-radius:0;
	width:30px !important;
	height:10px !important;
	background:#BDBDBD !important;
}
.ms-bullet-selected, .carousel-indicators li.active, .lSSlideOuter .lSPager.lSpg li.active a{
	background:#2bbbad !important;;
}
.carousel-indicators{
	bottom: -20px;
}

/* Quem somos */


/* Contruindo ideias */
.ca-construindo-item{
	margin-bottom:20px;
}
.pagination{
	margin:0;
}
.pagination > li,.pagination > li > a{
	border-radius:0 !important;
}
.pagination > li a,.pagination > li a:hover{
	border-color:#BDBDBD;
	background:#BDBDBD;
	color:#fff;
}
.pagination > li.disabled{
	display:none;
}
.pagination > li.active > span, .pagination > li.active > span:hover{
	border-color:#2bbbad;
	background-color:#2bbbad;
	color:#fff;
}
/* Portfolio */
#ca-clientes-lista{
	margin-top:15px;
}
/* Contato */
#ca-rodape-contatos li a{
	color:#231F20;
	font-size:1.1em;
	font-weight:400;
	padding:5px 0;
	display:block;
	text-align: left;
}
#ca-rodape-direita{
	background-repeat:no-repeat;
	background-size:cover;
}
#ca-rodape-form{
	position:relative;
	z-index:1;
	padding:40px;
}
#ca-rodape-form form{
	display:block;
	width:100%;
}
.form-control{
    padding: 29px 23px;
    border-radius:0;
    -webkit-border-radius:0;
}
.form-group{
	width:100%;
}
.btn-enviar{
	background: #2bbbad;
	color:#FFFFFF;
	border:none;
	border-radius:0;
    -webkit-border-radius:0;
    padding: 29px 5px;
}
.sem-padding{
	padding: 0 !important;
}
#ca-rodape{
	background:#231F20;
}
#ca-copyright{
	color:#fff;
	margin-top:20px;
}
#ca-logo-oni{
	margin:10px 0;
	display:block;
	float:right;
}

/* POPUP */
.ca-popup-somentetexto{
	background:#fff;
	padding:20px;
}
.ca-popup-html{
	background:#fff;
}
.ca-popup-padding{
	padding:35px 15px 20px 25px;
}
.ca-popup-autowidth .mfp-content{
	width:auto;
}
.mfp-close{
	background:#2bbbad !important;
	color:#FFFFFF !important;
	height:35px;
	width:35px;
	line-height:36px;
}
.mfp-image-holder .mfp-close{
	right:0;
	text-align:center;
	padding-right: 0;
	width:40px;
	margin-top:40px;
}
.mfp-close:active{
	top:0;
} 
.mfp-content{
	max-width:1170px;
}
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.ca-novidade-popup-img, .ca-construindo-popup-img{
	background-size:0%;
}

/* Scrollbar */
.mCSB_scrollTools{
	width:6px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	background-color:#2bbbad;
	border-radius: 0;
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
	background-color:#2bbbad;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
	background-color:#231F20;
}
.mCSB_scrollTools .mCSB_draggerRail{
	border-radius: 0;
	width:4px;
}

/* Galeria */
.lSSlideOuter .lSPager.lSGallery li.active, .lSSlideOuter .lSPager.lSGallery li:hover{
	border-radius:0;
	opacity:1;
}
.lSSlideOuter .lSPager.lSGallery li, .lSSlideOuter .lSPager.lSGallery li{
	opacity:0.5;
}

/* Slider light */
.cS-hidden {
    height: 1px;
    opacity: 0;
    filter: alpha(opacity=0);
    overflow: hidden;
}
.lSSlideOuter .lSPager.lSpg{
	margin-top: 20px;
}

/* RESPONSIVO */
.navbar-toggle{
	margin-top: 20px;
}
.navbar-toggle .icon-bar{
	background:#fff;
}

/* Media Querys */
@media(max-width:767px){
	#ca-menu-itens li a{
		margin:5px;
		padding:10px;
	}
	#ca-menu-itens li a:hover::after, #ca-menu-itens li.active a::after{
		opacity:0;
	}
	#ca-menu-itens li.active a{
		background:rgba(0, 0, 0, 0.5);
	}
	#ca-nav{
		background:rgba(0, 0, 0, 0.8) !important;
	}
	.navbar-header{
		background:#231F20;
	}

	#ca-quem-somos-imagem, #ca-como-fazemos-imagem{
		height:200px !important;
	}
	.faixa{
		display:block;
		min-height:unset !important;
		height:unset !important;
	}
	.ca-popup-scroll{
		max-height:500px;
		display:block;
	}
	.ca-novidade-popup-img img, .ca-construindo-popup-img img{
		display:none;
	}
	.ca-novidade-popup-img, .ca-construindo-popup-img{
		width: 100%;
	    height: 200px;
	    overflow: hidden;
	    display: block;
	    background-size:cover;
	    background-position:center center;
	}
	.ca-popup-padding{
		padding:10px;
	}
	#ca-rodape-form{
		padding:20px;
	}
	#ca-rodape-contatos li a{
		font-size: 0.9em;
	}
	#ca-copyright{
		font-size:12px;
	}
	#ca-logo-oni{
		margin: 22px 0;
	}
}
@media(min-width:768px){
	.navbar-brand{
		padding:15px 5px;
	}
	.navbar-brand > img{
		width:180px;
	}
	#ca-menu-itens li a{
		font-size: 13px;
		margin: 25px 7px;
	}
}
@media(min-width:992px){
	.navbar-brand{
		padding:15px;
	}
	.navbar-brand > img{
		width:unset;
	}
	#ca-menu-itens li a{
		font-size: inherit;
		margin: 25px 10px;
	}

}
@media(min-width:1200px){
	
}